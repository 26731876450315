<template>
  <ClientOnly fallback-tag="span" fallback="Please wait...">
    <v-app id="inspire">
      <system-bar />
      <v-snackbar v-model="website.snackbar.visible" :color="website.snackbar.color" location="top" variant="tonal" :multi-line="true">
        <v-row>
          <v-col class="flex-grow-0 flex-shrink-0 align-self-center">
            <v-icon size="x-large" :icon="website.snackbar.icon" />
          </v-col>
          <v-col class="flex-column">
            <div>{{ website.snackbar.title }}</div>
            <div class="text-xs">
              {{ website.snackbar.text }}
            </div>
          </v-col>
        </v-row>
      </v-snackbar>

      <v-navigation-drawer color="grey-darken-4" rail>
        <NuxtLink to="/main">
          <img :src="logo" class="object-contain rounded-full max-w-12 max-h-12 my-4 mx-1"></img>
        </NuxtLink>

        <v-divider :thickness="2" class="mx-1 my-2 border-opacity-50" />

        <NuxtLink to="/hq/dashboard">
          <v-avatar :image="hq" class="mx-2 my-2" />
        </NuxtLink>

        <NuxtLink to="/refinery/dashboard">
          <v-avatar :image="refinery" class="mx-2 my-2" />
        </NuxtLink>

        <v-divider :thickness="2" class="mx-1 my-2 border-opacity-50" />
        <logout />
      </v-navigation-drawer>

      <v-main>
        <NuxtPage />
      </v-main>
    </v-app>
  </ClientOnly>
</template>

<script setup>
import logo from '~/assets/img/logo.jpeg'
import hq from '~/assets/img/hq.jpg'
import refinery from '~/assets/img/refinery.jpeg'

const website = useWebsiteStore()
</script>
